import React, { useMemo } from "react";

import DashboardCard from "../Cards/DashboardCard";
import DashPanelCriteria from "components/components/Dashboard/DashPanelCriteria";

const ChargingInfraSection = ({ subSection, sectionId, countryCode, mobile = false }: any) => {
	const sectionData = useMemo(() => {
		return (
			<>
				{subSection?.criterias?.map((criteria: any) => (
					<DashPanelCriteria
						mobile={false}
						chargingInfra={mobile}
						countryCode={countryCode}
						key={Math.random().toString(36).slice(2)}
						criteria={criteria as any}
						isLast={1 === subSection?.criterias?.length}
						subSectionName={subSection.name}
						sectionId={sectionId}
						disablePadding
					/>
				))}
			</>
		);
	}, []);

	return (
		<>
			<DashboardCard title="Level 3 DCFC Equipment and Public Charging - BOCN and Ford Charge Used with DC Fast Chargers" caption="(at the Dealer's option and discretion)" fileUrl={subSection?.reportUrl} fileLinkText="View Verification Report" content={sectionData} />
		</>
	);
};

export default ChargingInfraSection;
