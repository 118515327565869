import { Button, Col, Row, Tag } from "components/components";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import AttestationDealerCard from "./AttestationDealerCard";
import FileUpload from "components/components/FileUpload";
import { usePostChargingInfraAttestationMutation, usePutChargingInfraAttestationMutation } from "services/attestations";
import { useNavigate } from "react-router-dom";
import useAuthInfo from "hooks/useAuthInfo";
import AttestationModal from "./AttestationModal";

const AttestationsChargingInfraForm = (props: any) => {
	const navigate = useNavigate();
	const { showSuccessBanner, editMode, defaultValues, dealerInfo, paCode, updateAttestationsCache } = props;

	const { wslId, preferredLanguage, countryCode } = useAuthInfo();

	const [postCIAttestation] = usePostChargingInfraAttestationMutation();
	const [putCIAttestation] = usePutChargingInfraAttestationMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		setError,
		setValue,
		clearErrors,
		reset,
		getValues,
	} = useForm({ defaultValues });

	useEffect(() => {
		if (defaultValues) {
			reset(defaultValues); // Reset form values with the new default values
		}
	}, [defaultValues, reset]);

	const submitAndShowBanner = (data: any) => {
		showSuccessBanner(data);
		scrollToTop();
	};

	const onSubmit = async (formValues: any, e: any) => {
		e.preventDefault();
		const mData = {
			...formValues,
			isEvEquipmentInstalled: formValues?.["isEvEquipmentInstalled"] === "Yes",
			paCode: dealerInfo?.paCode,
			salesCode: dealerInfo?.salesCode,
			wslId,
			prefferedLanguage: preferredLanguage,
			countryCode,
			level2ACChargerId: 5,
			nemaChargerId: 4,
			level2AMPChargerId: 6,
			optionName: "Charging Infrastructure",
		};
		const data = editMode ? { ...mData, isNemaFilesEdited: false, isLevel2AMPFilesEdited: false, isLevel2ACFilesEdited: false } : mData;
		const formData: any = new FormData();

		// Append data to FormData
		for (const key in data) {
			// If the value is an array (for multiple file uploads), loop through and append each file
			if (Array.isArray(data[key])) {
				data[key].forEach((file: File) => {
					formData.append(key, file); // Append each file
				});
			} else {
				formData.append(key, data[key]); // Append single values
			}
		}
		try {
			const method = editMode ? putCIAttestation : postCIAttestation;
			const { isError, message, data: respData } = await method(data).unwrap();
			// console.log({ data, isError, message, respData });
			scrollToTop();
			showSuccessBanner({ message: "Attestation successfully submitted", status: isError ? "error" : "success" });

			if (true) {
				if (!editMode) updateAttestationsCache(paCode, mData, null);
				setTimeout(() => {
					navigate(`/${paCode}/attestations`);
				}, 2000);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const [formVisible, setFormVisible] = useState(false);

	const beginForm = (e: any) => {
		const beginCheck = e.target.checked;
		beginCheck ? setFormVisible(true) : setFormVisible(false);
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	useEffect(() => {
		setFormVisible(editMode ? true : false);
	}, [editMode]);

	useEffect(() => {
		if (!!defaultValues) {
			const { nemaDocumentIds = [], level2ACDocumentIds = [], level2AMPDocumentIds = [] } = defaultValues || {};
			// console.log({ nemaDocumentIds, level2ACDocumentIds, level2AMPDocumentIds });
			setValue(
				"nemaFiles",
				nemaDocumentIds?.map((d: any) => d?.documentId)
			);
			setValue(
				"level2ACFiles",
				level2ACDocumentIds?.map((d: any) => d?.documentId)
			);
			setValue(
				"level2AMPFiles",
				level2AMPDocumentIds?.map((d: any) => d?.documentId)
			);
		}
	}, [defaultValues, setValue]);

	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const hasErrors = Object.keys(errors)?.length !== 0;
	const formFieldValues = watch();

	const optionalKeys = ["nemaChargerComments", "level2AMPChargerComments", "level2ACChargerComments"];
	const requiredFields = Object.keys(formFieldValues)
		?.filter((key) => !optionalKeys?.includes(key))
		?.reduce((acc, cur) => {
			return {
				...acc,
				[cur]: formFieldValues?.[cur],
			};
		}, {});
	const isAllFilled = Object.values(requiredFields).every((value) => {
		return value !== undefined && value !== "";
	});

	const isFilesPresent = useMemo(() => {
		return !!getValues("nemaFiles") && !!getValues("level2ACFiles") && !!getValues("level2AMPFiles");
	}, [formFieldValues, editMode, defaultValues]);

	const disableSubmit = !getValues("isDisclaimerChecked") === true;

	// console.log({ disableSubmit, hasErrors, isAllFilled, isFilesPresent });

	return (
		<div>
			<Col>
				<form onSubmit={handleSubmit(onSubmit)} className={"fds-m--y-2"}>
					<div className={"dealerTab-Section"}>
						<div className={"fds-p--x-1 fds-p--y-1"}>
							<h2 className={"fmc-type--heading6 fds-weight--bold letter-spacing-0 fds-p--b-2"}>Charging Infrastructure Attestation</h2>

							{!editMode && (
								<label htmlFor={"readyBegin"} className={"fds-color--primary"}>
									<input id={"readyBegin"} className={"fds-m--r-1"} type={"checkbox"} value={true as any} onChange={beginForm}></input>
									<span>I have all the necessary charging equipment installed and operational, and am ready to begin the attestation process.</span>
								</label>
							)}

							{formVisible ? (
								<Fragment>
									<div
										className={"fds-m--b-2"}
										style={{
											borderBottom: "1px solid var(--fds-color--gray3)",
										}}
									>
										<div className={" fds-m--y-2"}>
											<fieldset className={"fmc-check"} style={{ maxWidth: "unset" }}>
												<legend className={"fds-color--primary "}>Is all of the Electric Vehicle Supply Equipment (EVSE) installed at the dealer’s principal location?</legend>
												<label htmlFor={"EVSEEquipmentY"}>
													<input type={"radio"} id={"EVSEEquipmentY"} value={"Yes"} {...register("isEvEquipmentInstalled", { required: true, setValueAs: (val) => (val === true ? "Yes" : "No") })} />
													<span className={"fds-color--primary"}>Yes</span>
												</label>
												<label htmlFor={"EVSEEquipmentN"}>
													<input type={"radio"} id={"EVSEEquipmentN"} value={"No"} {...register("isEvEquipmentInstalled", { required: true, setValueAs: (val) => (val === true ? "Yes" : "No") })} />
													<span className={"fds-color--primary"}>No</span>
												</label>
											</fieldset>
										</div>
										<div className={" fds-m--y-2"}>
											<Col>
												<h3 className={"fmc-type--heading7 fds-weight--bold letter-spacing-0 fds-p--y-1"}>NEMA 14-50 Receptacles</h3>
												<p>Count of installed NEMA 14-50 receptacles that are installed, tested, and operating at the time of attestation. Minimum of three (3) 14-50 receptacles required.</p>
												<p className={"fds-m--y-2"}>Each receptacle is fed by its own 50A circuit breaker.</p>
											</Col>
										</div>

										<Col
											xs={4}
											sm={4}
											md={4}
											lg={2}
											className={"fmc-input--wrapper"}
											tag={"div"}
											padding={[
												{ dir: "b", amt: 1 },
												{ dir: "l", amt: 0 },
											]}
										>
											<label htmlFor={"installedNEMA"} className={" fds-color__text--primary "}>
												Installed NEMA 14-50
											</label>
											<select className={"fmc-select fds-m--t-1"} id={"installedNEMA"} {...register("nemaInstalledChargerCount", { required: true })}>
												<option value={"default"} hidden disabled selected>
													Select
												</option>
												<option value={"0"}>0</option>
												<option value={"1"}>1</option>
												<option value={"2"}>2</option>
												<option value={"3"}>3</option>
												<option value={"4"}>4</option>
												<option value={"5"}>5</option>
												<option value={"6"}>6</option>
												<option value={"7"}>7</option>
												<option value={"8"}>8</option>
												<option value={"9"}>9</option>
												<option value={"10"}>10</option>
												<option value={"11"}>11</option>
												<option value={"12"}>12</option>
												<option value={"13"}>13</option>
												<option value={"14"}>14</option>
												<option value={"15"}>15</option>
												<option value={"16"}>16</option>
												<option value={"17"}>17</option>
												<option value={"18"}>18</option>
												<option value={"19"}>19</option>
												<option value={"20"}>20</option>
												<option value={"21"}>21</option>
												<option value={"22"}>22</option>
												<option value={"23"}>23</option>
												<option value={"24"}>24</option>
												<option value={"26"}>25 or more</option>
											</select>
										</Col>

										<p className={"fds-p--y-2"}>
											Please upload pictures for each NEMA 14-50 receptacle with enough background to understand its placement in reference to the area in which it is located. Please make sure to descriptively name the files. (Ex. “NEMA 14-50 outlet 3 of 3”)
										</p>

										<Row className={"fds-m--y-2 "}>
											<FileUpload name="nemaFiles" initialFiles={defaultValues?.nemaDocumentIds} errors={errors} register={register} setError={setError} setValue={setValue} clearErrors={clearErrors} />
										</Row>

										<p className={"fds-p--y-2"}>Please provide any additional information or clarity that may be needed with what is noted above.</p>

										<Col xs={12} lg={6} className={"fds-p--x-0 fds-m--b-2"}>
											<label htmlFor={"nemaComments"} style={{ fontSize: "12px", color: "#4d4d4d" }}>
												NEMA 14-50 Comments (optional)
											</label>
											<textarea {...register("nemaChargerComments", { maxLength: 500 })} className={"w-100"} style={{ minHeight: "130px", padding: "10px" }} id={"nemaComments"} maxLength={500} />
											<small style={{ fontSize: "12px", color: "#4d4d4d" }}>{watch("nemaChargerComments") ? `${500 - watch("nemaChargerComments").length}` : "500"} Characters Remaining</small>
										</Col>
									</div>

									<div
										className={"fds-m--b-2"}
										style={{
											borderBottom: "1px solid var(--fds-color--gray3)",
										}}
									>
										<div className={" fds-m--y-2"}>
											<Col>
												<h3 className={"fmc-type--heading7 fds-weight--bold letter-spacing-0 fds-p--y-1"}>Level 2 AC Charging Station between 32-48 amps</h3>
												<p>
													The Next-Gen EV program contained a standard for two 32 amp or 48 amp Level 2 AC chargers. To allow Dealers to take advantage of existing infrastructure, Dealers will be allowed a one-time substitution of two 32 amp or 48 amp
													Level 2 chargers for one 80 amp charger. With this update, two 32 (or 48) amp chargers, and one 80 amp charger (or two 80 amp chargers) meet the Electric Vehicle Competency. Count of 32-48A Level 2 stations that are installed,
													tested, and operating at the time of attestation. Fed by a dedicated 40A to 60A circuit breaker. Operation confirmed via live test with an EV or EVSE tester.
												</p>
												<p className={"fds-p--y-2"}>Count of 32-48A Level 2 stations that are installed, tested, and operating at the time of attestation.</p>
												<p className={"fds-p--y-2"}>Fed by a dedicated 40A to 60A circuit breaker. Operation confirmed via live test with an EV or EVSE tester.</p>
											</Col>
										</div>

										<Col
											xs={4}
											sm={4}
											md={4}
											lg={2}
											className={"fmc-input--wrapper"}
											tag={"div"}
											padding={[
												{ dir: "b", amt: 1 },
												{ dir: "l", amt: 0 },
											]}
										>
											<label htmlFor={"installed3248"} className={" fds-color__text--primary "}>
												Installed 32-48A Level 2
											</label>
											<select className={"fmc-select fds-m--t-1"} id={"installed3248"} {...register("level2ACInstalledChargerCount", { required: true })}>
												<option value={"default"} hidden disabled selected>
													Select
												</option>
												<option value={"0"}>0</option>
												<option value={"1"}>1</option>
												<option value={"2"}>2</option>
												<option value={"3"}>3</option>
												<option value={"4"}>4</option>
												<option value={"5"}>5</option>
												<option value={"6"}>6</option>
												<option value={"7"}>7</option>
												<option value={"8"}>8</option>
												<option value={"9"}>9</option>
												<option value={"10"}>10</option>
												<option value={"11"}>11</option>
												<option value={"12"}>12</option>
												<option value={"13"}>13</option>
												<option value={"14"}>14</option>
												<option value={"15"}>15</option>
												<option value={"16"}>16</option>
												<option value={"17"}>17</option>
												<option value={"18"}>18</option>
												<option value={"19"}>19</option>
												<option value={"20"}>20</option>
												<option value={"21"}>21</option>
												<option value={"22"}>22</option>
												<option value={"23"}>23</option>
												<option value={"24"}>24</option>
												<option value={"26"}>25 or more</option>
											</select>
										</Col>

										<Col
											xs={4}
											sm={4}
											md={4}
											lg={2}
											className={"fmc-input--wrapper fds-m--y-2"}
											tag={"div"}
											padding={[
												{ dir: "b", amt: 1 },
												{ dir: "l", amt: 0 },
											]}
										>
											<label htmlFor={"installed3248Make"} className={" fds-color__text--primary fds-wrap--nowrap"}>
												32-48A Level 2 Station Make
											</label>
											<select className={"fmc-select fds-m--t-1"} id={"installed3248Make"} {...register("level2ACChargerHardwareMake", { required: true })}>
												<option value={"default"} hidden disabled selected>
													Select
												</option>
												<option value={"ford-pro"}>Ford Pro</option>
												<option value={"abb"}>ABB</option>
												<option value={"autel"}>Autel</option>
												<option value={"blink"}>Blink</option>
												<option value={"chargepoint"}>ChargePoint</option>
												<option value={"charge-pro"}>Charge Pro</option>
												<option value={"enel-x"}>Enel X</option>
												<option value={"evo-charge"}>Evo Charge</option>
												<option value={"incharge"}>InCharge</option>
												<option value={"phihong"}>Phihong</option>
												<option value={"powercharge"}>PowerCharge</option>
												<option value={"red-e"}>Red E</option>
												<option value={"sema-connect"}>Sema Connect</option>
												<option value={"tellus-power"}>Tellus Power</option>
												<option value={"xova"}>XOVA</option>
												<option value={"zerova"}>Zerova</option>
												<option value={"other"}>Other</option>
											</select>
										</Col>

										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											className={"fmc-input--wrapper fds-m--y-2"}
											tag={"div"}
											padding={[
												{ dir: "b", amt: 1 },
												{ dir: "l", amt: 0 },
											]}
										>
											<label htmlFor={"installed3248Model"} className={" fds-color__text--primary "}>
												32-48A Level 2 Station Model
											</label>
											<input type={"text"} className={"fmc-input fds-m--t-1"} id={"installed3248Model"} {...register("level2ACChargerHardwareModel", { required: true })} />
										</Col>
										<p className={"fds-p--y-2"}>
											Please upload pictures for each 32-48A Level 2 AC charger (if applicable) with enough background to understand its placement in reference to the area in which it is located. Please make sure to descriptively name the files. (Ex. “32A
											Level 2 Charger 1 of 1”)
										</p>

										<Row className={"fds-m--y-2 "}>
											<FileUpload name="level2ACFiles" initialFiles={defaultValues?.level2ACDocumentIds} errors={errors} register={register} setError={setError} setValue={setValue} clearErrors={clearErrors} />
										</Row>

										<p className={"fds-p--y-2"}>Please provide any additional information or clarity that may be needed with what is noted above.</p>

										<Col xs={12} lg={6} className={"fds-p--x-0 fds-m--b-2"}>
											<label htmlFor={"32AComments"} style={{ fontSize: "12px", color: "#4d4d4d" }}>
												32-48A Level 2 charger Comments (optional)
											</label>
											<textarea {...register("level2ACChargerComments", { maxLength: 500 })} className={"w-100"} style={{ minHeight: "130px", padding: "10px" }} id={"32AComments"} maxLength={500} />
											<small style={{ fontSize: "12px", color: "#4d4d4d" }}>{watch("level2ACChargerComments") ? `${500 - watch("level2ACChargerComments").length}` : "500"} Characters Remaining</small>
										</Col>
									</div>
									<div className={"fds-m--b-2"}>
										<div className={" fds-m--y-2"}>
											<Col>
												<h3 className={"fmc-type--heading7 fds-weight--bold letter-spacing-0 fds-p--y-1"}>Level 2 80 amp Charging Stations</h3>
												<p>Count of Level 2 80A stations that are installed, tested, and operating at the time of attestation.</p>
												<p className={"fds-p--y-2"}>
													Count of Level 2 80A stations that are installed, tested, and operating at the time of attestation. Each charger is fed by a 100A circuit breaker and capable of 19.2 kW (240V) or 16.6 kW (208V). Operation confirmed via live test
													with an EV or EVSE tester.
												</p>
											</Col>
										</div>

										<Col
											xs={4}
											sm={4}
											md={4}
											lg={2}
											className={"fmc-input--wrapper"}
											tag={"div"}
											padding={[
												{ dir: "b", amt: 1 },
												{ dir: "l", amt: 0 },
											]}
										>
											<label htmlFor={"installed80A"} className={" fds-color__text--primary "}>
												Installed Level 2 80A
											</label>
											<select className={"fmc-select fds-m--t-1"} id={"installed80A"} {...register("level2AMPInstalledChargerCount", { required: true })}>
												<option value={"default"} hidden disabled selected>
													Select
												</option>
												<option value={"0"}>0</option>
												<option value={"1"}>1</option>
												<option value={"2"}>2</option>
												<option value={"3"}>3</option>
												<option value={"4"}>4</option>
												<option value={"5"}>5</option>
												<option value={"6"}>6</option>
												<option value={"7"}>7</option>
												<option value={"8"}>8</option>
												<option value={"9"}>9</option>
												<option value={"10"}>10</option>
												<option value={"11"}>11</option>
												<option value={"12"}>12</option>
												<option value={"13"}>13</option>
												<option value={"14"}>14</option>
												<option value={"15"}>15</option>
												<option value={"16"}>16</option>
												<option value={"17"}>17</option>
												<option value={"18"}>18</option>
												<option value={"19"}>19</option>
												<option value={"20"}>20</option>
												<option value={"21"}>21</option>
												<option value={"22"}>22</option>
												<option value={"23"}>23</option>
												<option value={"24"}>24</option>
												<option value={"26"}>25 or more</option>
											</select>
										</Col>

										<Col
											xs={4}
											sm={4}
											md={4}
											lg={2}
											className={"fmc-input--wrapper fds-m--y-2"}
											tag={"div"}
											padding={[
												{ dir: "b", amt: 1 },
												{ dir: "l", amt: 0 },
											]}
										>
											<label htmlFor={"installed80AMake"} className={" fds-color__text--primary fds-wrap--nowrap"}>
												Installed Level 2 80A Station Make
											</label>
											<select className={"fmc-select fds-m--t-1"} id={"installed80AMake"} {...register("level2AMPChargerHardwareMake", { required: true })}>
												<option value={"default"} hidden disabled selected>
													Select
												</option>
												<option value={"ford-pro"}>Ford Pro</option>
												<option value={"abb"}>ABB</option>
												<option value={"autel"}>Autel</option>
												<option value={"blink"}>Blink</option>
												<option value={"chargepoint"}>ChargePoint</option>
												<option value={"charge-pro"}>Charge Pro</option>
												<option value={"enel-x"}>Enel X</option>
												<option value={"evo-charge"}>Evo Charge</option>
												<option value={"incharge"}>InCharge</option>
												<option value={"phihong"}>Phihong</option>
												<option value={"powercharge"}>PowerCharge</option>
												<option value={"red-e"}>Red E</option>
												<option value={"sema-connect"}>Sema Connect</option>
												<option value={"tellus-power"}>Tellus Power</option>
												<option value={"xova"}>XOVA</option>
												<option value={"zerova"}>Zerova</option>
												<option value={"other"}>Other</option>
											</select>
										</Col>

										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											className={"fmc-input--wrapper fds-m--y-2"}
											tag={"div"}
											padding={[
												{ dir: "b", amt: 1 },
												{ dir: "l", amt: 0 },
											]}
										>
											<label htmlFor={"installed80AModel"} className={" fds-color__text--primary "}>
												Level 2 80A Station Model
											</label>
											<input type={"text"} className={"fmc-input fds-m--t-1"} id={"installed80AModel"} {...register("level2AMPChargerHardwareModel", { required: true })} />
										</Col>
										<p className={"fds-p--y-2"}>
											Please upload pictures for each 80A Level 2 AC charger (if applicable) with enough background to understand its placement in reference to the area in which it is located. Please make sure to descriptively name the files. (Ex. “80A Level
											2 Charger 1 of 1”)
										</p>

										<Row className={"fds-m--y-2 "}>
											<FileUpload name="level2AMPFiles" initialFiles={defaultValues?.level2AMPDocumentIds} register={register} errors={errors} setError={setError} setValue={setValue} clearErrors={clearErrors} />
										</Row>

										<p className={"fds-p--y-2"}>Please provide any additional information or clarity that may be needed with what is noted above.</p>

										<Col xs={12} lg={6} className={"fds-p--x-0 fds-m--b-2"}>
											<label htmlFor={"80AComments"} style={{ fontSize: "12px", color: "#4d4d4d" }}>
												80A Level 2 charger Comments (optional)
											</label>
											<textarea {...register("level2AMPChargerComments", { maxLength: 500 })} className={"w-100"} style={{ minHeight: "130px", padding: "10px" }} id={"80AComments"} maxLength={500} />
											<small style={{ fontSize: "12px", color: "#4d4d4d" }}>{watch("level2AMPChargerComments") ? `${500 - watch("level2AMPChargerComments").length}` : "500"} Characters Remaining</small>
										</Col>
									</div>
								</Fragment>
							) : (
								""
							)}
						</div>
					</div>

					{formVisible ? (
						<Fragment>
							<div className={"dealerTab-Section fds-m--y-2"}>
								<AttestationDealerCard dealerInformation={{ ...defaultValues, ...dealerInfo }} register={register} errors={errors} />
							</div>

							<div className={"dealerTab-Section fds-m--y-2"}>
								<Col className={"fds-p--x-1 fds-p--y-1"}>
									<h2 className={"fmc-type--heading6 fds-weight--bold letter-spacing-0 fds-p--b-2"}>Attestation</h2>

									<label htmlFor={"authorityCheck"} className={"fds-color--primary"} style={{ display: "flex", alignItems: "baseline" }}>
										<input id={"authorityCheck"} className={"fds-m--r-1"} type={"checkbox"} value={true as any} {...register("isDisclaimerChecked", { required: true })} style={{ marginRight: "1.5rem" }} />
										<span>
											By checking the box and submitting this form, I attest that I am an f(ii) authority or authorized by a member of f(ii) authority and attest on behalf of the Dealership listed under the Dealership's Sales Code listed above and that the
											information provided is true and correct.
										</span>
									</label>
								</Col>
								<Button buttonClassName={"fds-m--y-2"} rightIcon noShadow disabled={disableSubmit || hasErrors || !isAllFilled || !isFilesPresent}>
									Submit Attestation
								</Button>
							</div>
						</Fragment>
					) : (
						""
					)}
				</form>
			</Col>
		</div>
	);
};

export default AttestationsChargingInfraForm;
