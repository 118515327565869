// src/services/reports-api.service.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { DropdownOptionsApiResponse, MasterStatusReportParams, PhqReportParams, UserPreferenceReportParams } from "./reports.types";
import { createBaseQuery } from "./overrides/createBaseQuery";
import { getAccessToken, getTokenAuthDetails } from "providers/AuthProvider";

// Modify the headers construction to avoid null values
const constructHeaders = () => {
	const token = getAccessToken();
	const { wslId, countryCode, preferredLanguage } = getTokenAuthDetails();

	const headers = new Headers({
		Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
		"x-api-key": process.env.REACT_APP_GATEWAY_API_KEY || "",
		apikey: process.env.REACT_APP_GATEWAY_API_KEY || "",
	});

	// Conditionally append token and other details if they are truthy
	if (token) headers.append("MMED_ACCESS_TOKEN", token);
	if (wslId) headers.append("wslId", wslId);
	if (countryCode) headers.append("countryCode", countryCode);
	if (preferredLanguage) headers.append("preferredLanguage", preferredLanguage);

	return headers;
};

export const reportsApi = createApi({
	reducerPath: "reportsApi",
	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_REPORTS_API_HOST}/reports`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
			includeMmedAccessToken: true,
			includeWslId: true,
		},
	}),
	endpoints: (builder) => ({
		getMasterStatusReportDropdownOptions: builder.query<DropdownOptionsApiResponse, string>({
			query: (countryCode: string) => `ms/dropDownData/${countryCode}`,
		}),
		getPhqReportDropdownOptions: builder.query<DropdownOptionsApiResponse, string>({
			query: (countryCode: string) => `phq/dropDown/${countryCode}`,
		}),
		getUserPreferencesReportDropdownOptions: builder.query<DropdownOptionsApiResponse, string>({
			query: (countryCode: string) => `phq/dropDown/${countryCode}`,
		}),
		getUserPreferenceReport: builder.query<Blob, UserPreferenceReportParams>({
			queryFn: async ({ marketArea, region, zone, certified, qualified, dealerCode, paCode, countryCode }) => {
				const url = `${process.env.REACT_APP_REPORTS_API_HOST}/reports/preference/userPreferenceReport/${marketArea}/${region}/${zone}/${certified}/${qualified}/${dealerCode}/${paCode}/${countryCode}`;
				try {
					const response = await fetch(url, {
						method: "GET",
						headers: constructHeaders(),
					});
					if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
					return { data: await response.blob() };
				} catch (error) {
					const message = error instanceof Error ? error.message : "An unknown error occurred";
					console.error("Error fetching user preference report:", message);
					return { error: { status: "FETCH_ERROR", error: message } };
				}
			},
		}),
		getMasterStatusReport: builder.query<Blob, MasterStatusReportParams>({
			queryFn: async ({ marketArea, region, zone, certified, qualified, countryCode }) => {
				const url = `${process.env.REACT_APP_REPORTS_SERVICE_API_HOST}/reports/ms/${marketArea}/${region}/${zone}/${certified}/${qualified}/${countryCode}`;
				try {
					const response = await fetch(url, {
						method: "GET",
						headers: constructHeaders(),
					});
					if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
					return { data: await response.blob() };
				} catch (error) {
					const message = error instanceof Error ? error.message : "An unknown error occurred";
					console.error("Error fetching master status report:", message);
					return { error: { status: "FETCH_ERROR", error: message } };
				}
			},
		}),
		getPhqReport: builder.query<Blob, PhqReportParams>({
			queryFn: async ({ reportType, marketArea, region, zone, dealerCode, paCode, countryCode }) => {
				const url = `${process.env.REACT_APP_REPORTS_API_HOST}/reports/phq/${reportType}/${marketArea}/${region}/${zone}/${dealerCode}/${paCode}/${countryCode}`;
				try {
					const response = await fetch(url, {
						method: "GET",
						headers: constructHeaders(),
					});
					if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
					return { data: await response.blob() };
				} catch (error) {
					const message = error instanceof Error ? error.message : "An unknown error occurred";
					console.error("Error fetching PHQ report:", message);
					return { error: { status: "FETCH_ERROR", error: message } };
				}
			},
		}),
	}),
});

export const { useGetMasterStatusReportDropdownOptionsQuery, useGetPhqReportDropdownOptionsQuery, useGetUserPreferencesReportDropdownOptionsQuery, useGetUserPreferenceReportQuery, useGetMasterStatusReportQuery, useGetPhqReportQuery } = reportsApi;
