import React from "react";
import { Button, Col, Row } from "components/components";
import { getDocumentURL } from "components/components/Dashboard/MeQAccordion";
import { CHARGER_MAKES } from "../constants";
import { extractFileNameFromUrl } from "components/components/FileUpload";

interface attestationViewProps {
	attestationCI: any;
	attestationTE: any;
	paCode: string;
}

interface NEMAAttestation {
	attestOrSkip: "Attested" | "Skipped";
	installed: number | "25+";
	imageItems: imageItems[];
	comments?: string;
	Level2: Level2;
	Level280: Level2;
}

interface Level2 {
	attestOrSkip?: "Attested" | "Skipped";
	installed: number | "25+";
	make: string;
	model: string;
	imageItems: imageItems[];
	comments?: string;
}

interface imageItems {
	src: string;
	imgName: string;
}

interface toolsAttestation {
	forklift: "Own" | "Access" | "N/A";
	EVDollies: "Yes" | "No";
	HVCones: "Yes" | "No";
	HVBatteryLift: "Yes" | "No";
	RescueCharger: "Yes" | "No";
	SmokeMachine: "Yes" | "No";
	HVArrayCharger: "Yes" | "No";
	PGun: "Yes" | "No";
	PressureTest: "Yes" | "No";
	EVToolKit: "Yes" | "No";
	F150ToolKit: "Yes" | "No";
	EVHoist: "Yes" | "No";
}

export interface attestationEntry {
	allEVSEInstalled: boolean;
	NEMAAttestation?: NEMAAttestation;
	NEMAsubmissionDate: string;
	NEMAapprovalDate?: string;
	toolsAttestation?: toolsAttestation;
}

const AttestationView = (props: attestationViewProps) => {
	const { attestationCI, attestationTE, paCode } = props;

	// Charging Infrastructure Data
	const {
		isEvEquipmentInstalled,
		nemaInstalledChargerCount,
		nemaChargerComments,
		nemaDocumentIds = [],
		level2ACInstalledChargerCount,
		level2ACChargerHardwareMake,
		level2ACChargerHardwareModel,
		level2ACChargerComments,
		level2ACDocumentIds = [],
		level2AMPInstalledChargerCount,
		level2AMPChargerHardwareMake,
		level2AMPChargerHardwareModel,
		level2AMPChargerComments,
		level2AMPDocumentIds = [],
		submittedDate: ciSubmittedDate = "",
		approvedDate: ciApprovedDate = "",
	} = attestationCI || {};

	const { submittedDate: teSubmittedDate = "", approvedDate: teApprovedDate = "" } = attestationTE || {};

	const ciAttes = !attestationCI ? { url: `/${paCode}/add-attestation`, label: "Add Attestation" } : { url: `/${paCode}/edit-attestation`, label: "Edit Attestation" };
	const teAttes = !attestationTE ? { url: `/${paCode}/add-attestation/tools-equipment`, label: "Add Attestation" } : { url: `/${paCode}/edit-attestation/tools-equipment`, label: "Edit Attestation" };

	// Tools & Equipment Data
	const toolMapping: any = {
		TP1: "Forklift",
		TP2: "EV Battery Dollies",
		TP3: "High Voltage Caution Cones",
		TP4: "High Voltage Battery Lift Table",
		TP5: "Rescue Charger",
		TP6: "Smoke Machine",
		TP7: "High Voltage Battery Array Charger",
		TP8: "Pneumatic Gun",
		TP9: "Pressure Test Set",
		TP10: "EV ESST Tool Kit",
		TP11: "F-150 Lightning ESST Tool Kit",
		TP12: "Hoist/Lift",
	};

	const renderToolStatus = (response: any) => {
		const toolName = toolMapping[response.criteriaCode];
		const status = response.isCriteriaCompleted ? "Yes" : "No";
		return (
			<p key={response.criteriaId} className={"fds-p--y-2"}>
				{toolName}: <span className={"fds-weight--bold"}>{status}</span>
			</p>
		);
	};

	return (
		<div>
			{/* Charging Infrastructure Section */}
			<div className={"dealerTab-Section"}>
				<Row className={"fds-flex__justify--between "}>
					<h2 className={"fds-p--y-1"}>Charging Infrastructure</h2>

					<Button tag="a" href={ciAttes.url} ariaLabel={"Edit Attestation"} rightIcon textButton target="_self">
						{" "}
						{ciAttes.label}
					</Button>
				</Row>

				{attestationCI ? (
					<Col className={"fds-m--y-1"}>
						<p className={"fds-p--y-2"}>
							Is all of the Electric Vehicle Supply Equipment (EVSE) installed at the dealer’s principal location? <span className={"fds-weight--bold"}>{isEvEquipmentInstalled === true ? "Yes" : "No"} </span>
						</p>

						{/* NEMA 14-50 Receptacles */}
						<h3 className={"fds-p--y-2"}>NEMA 14-50 Receptacles</h3>
						<Col className={"fds-p--b-3"} style={{ borderBottom: "1px solid var(--fds-color--gray3)" }}>
							<p>
								Installed NEMA 14-50: <span className={"fds-weight--bold"}>{nemaInstalledChargerCount == "26" ? "25 or more" : nemaInstalledChargerCount}</span>
							</p>
							<div>
								<p>Attached Images:</p>
								<Row className={"attestationImgContainer fds-m--b-2"}>
									{nemaDocumentIds?.map((docId: any, index: number) => (
										<Col lg={2} key={index} className={"fds-flex fds-flex-direction--column fds-p--x-0 fds-m--y-1"}>
											<img src={docId?.documentURL} className={"exPhotoContainer"} />
											<p className="fds-color--gray3" style={{ fontSize: "1.1rem", overflowWrap: "break-word", maxWidth: 210, wordBreak: "break-word" }}>
												{docId?.documentName ?? extractFileNameFromUrl(docId?.documentURL)}
											</p>
										</Col>
									))}
								</Row>
							</div>
							<p>
								<span className={"fds-weight--bold"}>NEMA 14-50 Comments:</span> {nemaChargerComments}
							</p>
						</Col>

						{/* Level 2 AC Charging Stations */}
						<h3 className={"fds-p--y-2"}>Level 2 AC Charging Stations between 32-48 amps</h3>
						<Col className={"fds-p--b-3"} style={{ borderBottom: "1px solid var(--fds-color--gray3)" }}>
							<p>
								Installed 32-48A Level 2: <span className={"fds-weight--bold"}>{level2ACInstalledChargerCount == "26" ? "25 or more" : level2ACInstalledChargerCount}</span>
							</p>
							<p>
								32-48A Level 2 Charger Make: <span className={"fds-weight--bold"}>{CHARGER_MAKES?.[level2ACChargerHardwareMake]}</span>
							</p>
							<p>
								32-48A Level 2 Charger Model: <span className={"fds-weight--bold"}>{level2ACChargerHardwareModel}</span>
							</p>
							<div>
								<p>Attached Images:</p>
								<Row className={"attestationImgContainer fds-m--b-2"}>
									{level2ACDocumentIds?.map((docId: any, index: number) => {
										const urlObj = new URL(docId?.documentURL);
										urlObj.searchParams.set("response-content-disposition", "inline");
										const mURL = urlObj.toString();

										return (
											<Col lg={2} key={index} className={"fds-flex fds-flex-direction--column fds-p--x-0 fds-m--y-1"}>
												<img src={docId?.documentURL} className={"exPhotoContainer"} />
												<p className="fds-color--gray3" style={{ fontSize: "1.1rem", overflowWrap: "break-word", maxWidth: 210, wordBreak: "break-word" }}>
													{docId?.documentName ?? extractFileNameFromUrl(docId?.documentURL)}
												</p>
											</Col>
										);
									})}
								</Row>
							</div>
							<p>
								<span className={"fds-weight--bold"}>Level 2 AC Charging Comments:</span> {level2ACChargerComments}
							</p>
						</Col>

						{/* Level 2 80 amp Charging Stations */}
						<h3 className={"fds-p--y-2"}>Level 2 80 amp Charging Stations</h3>
						<Col className={"fds-p--b-4"}>
							<p>
								Installed Level 2: <span className={"fds-weight--bold"}>{level2AMPInstalledChargerCount == "26" ? "25 or more" : level2AMPInstalledChargerCount}</span>
							</p>
							<p>
								Level 2 80A Station Make: <span className={"fds-weight--bold"}>{CHARGER_MAKES?.[level2AMPChargerHardwareMake]}</span>
							</p>
							<p>
								Level 2 80A Station Model: <span className={"fds-weight--bold"}>{level2AMPChargerHardwareModel}</span>
							</p>
							<div>
								<p>Attached Images:</p>
								<Row className={"attestationImgContainer fds-m--b-2"}>
									{level2AMPDocumentIds?.map((docId: any, index: number) => (
										<Col lg={2} key={index} className={"fds-flex fds-flex-direction--column fds-p--x-0 fds-m--y-1"}>
											<img src={docId?.documentURL} className={"exPhotoContainer"} />
											<p className="fds-color--gray3" style={{ fontSize: "1.1rem", overflowWrap: "break-word", maxWidth: 210, wordBreak: "break-word" }}>
												{docId?.documentName ?? extractFileNameFromUrl(docId?.documentURL)}
											</p>
										</Col>
									))}
								</Row>
							</div>
							<p>
								<span className={"fds-weight--bold"}>Level 2 AC 80 amp Charging Comments:</span> {level2AMPChargerComments}
							</p>
						</Col>

						<Col className={"fds-p--b-1"}>
							<h3 className={"fds-p--y-2"}>Attestation Date</h3>
							<p>
								Submitted: <span className={"fds-weight--bold"}> {ciSubmittedDate}</span>
							</p>
							<p>
								Approved: <span className={"fds-weight--bold"}> {ciApprovedDate}</span>
							</p>
						</Col>
					</Col>
				) : (
					<Col className={"fds-m--y-1"}>
						<p>No attestation on file.</p>
					</Col>
				)}
			</div>

			{/* Tools & Equipment Section */}
			<div className={"dealerTab-Section fds-m--y-3"}>
				<Row className={"fds-flex__justify--between "}>
					<h2 className={"fds-p--y-1"}>Tools & Equipment</h2>

					<Button tag="a" href={teAttes?.url} ariaLabel={teAttes?.label} rightIcon textButton target="_self">
						{" "}
						{teAttes?.label}
					</Button>
				</Row>

				{attestationTE?.responses ? (
					<>
						<Col className={"fds-m--y-2"}>{attestationTE?.responses?.map(renderToolStatus)}</Col>
						<Col className={"fds-p--b-1"}>
							<h3 className={"fds-p--y-2"}>Attestation Date</h3>
							<p>
								Submitted: <span className={"fds-weight--bold"}> {teSubmittedDate}</span>
							</p>
							<p>
								Approved: <span className={"fds-weight--bold"}> {teApprovedDate}</span>
							</p>
						</Col>
					</>
				) : (
					<Col className={"fds-m--y-1"}>
						<p>No attestation on file.</p>
					</Col>
				)}
			</div>
		</div>
	);
};

export default AttestationView;
