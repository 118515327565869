import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useGetMasterStatusReportQuery } from "services/reports";
import Breadcrumbs from "components/components/Navigation/Breadcrumbs";
import { useMasterStatusReportDropdownOptions } from "hooks/useMasterStatusReportDropdownOptions";
import { Col, Row } from "components/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { FormikSelectField } from "components/components/Form/FormikSelectField";
import ReportDownloadButton from "components/components/Form/ReportDownloadButton";
import useDownload from "hooks/useDownload";

export const MasterStatusReport: React.FC = () => {
	const { countryCode } = useSelector((state: RootState) => state.localePreferences);
	const {
		certifiedTypesOptions,
		marketAreaOptions,
		getRegionOptions: allRegionOptions, // Use allRegionOptions to get all regions regardless of market
		getZoneOptions: getZoneOptions,
		isError,
		isLoading: isDropdownLoading,
	} = useMasterStatusReportDropdownOptions(countryCode);

	const formik = useFormik({
		initialValues: {
			certificationType: "All",
			qualificationResults: "All",
			marketAreaName: "All",
			regionName: "All",
			zone: "All",
		},
		onSubmit: (values) => {
			setDownloadTriggered(true);
		},
	});

	const [downloadTriggered, setDownloadTriggered] = useState(false);
	const [selectedMarketArea, setSelectedMarketArea] = useState("All");
	const [selectedRegion, setSelectedRegion] = useState("All");

	const { download, isDownloading } = useDownload();

	useEffect(() => {
		if (selectedMarketArea === "All") {
			// Allow all regions if no specific market area is selected
			formik.setFieldValue("regionName", selectedRegion);
		} else {
			// Filter regions based on selected market area and reset selected region
			const filteredRegions = allRegionOptions(selectedMarketArea);
			if (!filteredRegions.includes(selectedRegion)) {
				setSelectedRegion("All"); // Reset selected region if it's not in the new filtered list
				formik.setFieldValue("regionName", "All");
			}
			formik.setFieldValue("marketAreaName", selectedMarketArea);
		}
	}, [selectedMarketArea]);

	useEffect(() => {
		// Update Formik when selectedRegion changes
		formik.setFieldValue("regionName", selectedRegion);
		// Reset zones when changing regions
		if (selectedRegion === "All") {
			formik.setFieldValue("zone", "All");
		}
	}, [selectedRegion]);

	// Dynamically compute zone options based on the selected region
	const zoneOptions = selectedRegion === "All" ? ["All"] : getZoneOptions(selectedRegion);

	const { marketAreaName: marketArea, regionName: region, zone, certificationType: certified, qualificationResults: qualified } = formik.values || {};

	const masterReportUrl = useMemo(() => {
		return `${process.env.REACT_APP_REPORTS_SERVICE_API_HOST}/reports/ms/${marketArea}/${region}/${zone}/${certified}/${qualified}/${countryCode}`;
	}, [marketArea, region, zone, certified, qualified, countryCode]);

	return (
		<>
			<Col xs={12} className="fds-p--y-1">
				<Breadcrumbs
					className="fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"
					cta={[
						{ href: "/reports", label: "Reports" },
						{ href: "/reports/masterstatus", label: "Ford Model e Qualifiers Status Report" },
					]}
				/>

				<Row className="fds-flex--center">
					<Col xs={12} md={8} className="fds-flex--center fds-flex-direction--column fds-p--b-3">
						<h1 className="fmc-type--heading3 fds-color--primary fds-align--left fds-md:fds-align--center fds-p--b-1">Ford Model e™ Qualifiers Master Status Report</h1>
						<p className="fmc-type--body2 fds-font--color__gray fds-flex--center fds-align--left fds-md:fds-align--center fds-p--b-2 fds-p--r-1">
							Summary of Ford Model e Qualifiers standard monthly statuses for all active Ford Model e Dealers. Report can only be generated for one selected month. Report can be pre-filtered before generating by Certification Type, Qualification Status, and/or
							Regional Hierarchy.
						</p>
					</Col>
				</Row>

				<form onSubmit={formik.handleSubmit}>
					<Row className="fds-m--x-3 fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3">
						<Col className="fds-xs:fds-flex__col-12 fds-xs:fds-m--y-2 fds-md:fds-m--y-1 fds-md:fds-flex__col-auto fds-md:fds-m--r-2 masterSelections">
							<FormikSelectField name="certificationType" label="Certification Type" options={certifiedTypesOptions.map((c) => ({ label: c, value: c }))} formik={formik} />
						</Col>
						<Col className="fds-xs:fds-flex__col-12 fds-xs:fds-m--y-2 fds-md:fds-m--y-1 fds-md:fds-flex__col-auto fds-md:fds-m--r-2 masterSelections">
							<FormikSelectField
								name="qualificationResults"
								label="Qualification Results"
								options={[
									{ label: "All", value: "All" },
									{ label: "Qualified", value: "Qualified" },
									{ label: "Non-Qualified", value: "Non-Qualified" },
								]}
								formik={formik}
							/>
						</Col>
						<Col className="fds-xs:fds-flex__col-12 fds-xs:fds-m--y-2 fds-md:fds-m--y-1 fds-md:fds-flex__col-auto fds-md:fds-m--r-2 masterSelections">
							<FormikSelectField name="marketAreaName" label="Market" options={marketAreaOptions.map((m) => ({ label: m, value: m }))} formik={formik} onChange={(e) => setSelectedMarketArea(e.target.value)} />
						</Col>
						<Col className="fds-xs:fds-flex__col-12 fds-xs:fds-m--y-2 fds-md:fds-m--y-1 fds-md:fds-flex__col-auto fds-md:fds-m--r-2 masterSelections">
							<FormikSelectField name="regionName" label="Region" options={allRegionOptions(selectedMarketArea).map((r) => ({ label: r, value: r }))} formik={formik} onChange={(e) => setSelectedRegion(e.target.value)} />
						</Col>
						<Col className="fds-xs:fds-flex__col-12 fds-xs:fds-m--y-2 fds-md:fds-m--y-1 fds-md:fds-flex__col-auto fds-md:fds-m--r-2 masterSelections">
							<FormikSelectField name="zone" label="Zone" options={zoneOptions.map((z) => ({ label: z, value: z }))} formik={formik} disabled={selectedRegion === "All"} />
						</Col>
					</Row>
					<div className="fds-m--y-4 fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-xs:fds-align--center fds-md:fds-align--left">
						<ReportDownloadButton onClick={(e) => download(e, masterReportUrl, "MasterReport.xlsx")} isDropdownFetching={isDropdownLoading} isReportLoading={isDownloading} />
					</div>
				</form>
			</Col>
		</>
	);
};

export default MasterStatusReport;
