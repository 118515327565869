import { Fragment } from "react";
import { TopBanner } from "..";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import QualificationPeriodReminderBanner from "./QualificationPeriodReminderBanner";

const QualificationPeriodBanner = ({ countryCode }: any) => {
	const { t } = useTranslation();
	const edcLink = `https://www.fmcdealer.dealerconnection.com/content/dam/fmcdealer/documents/edc/2024/EFC14438.pdf`;
	const windowWidth = useSelector((state: RootState) => state.windowSize.width);
	const linkText = t("pages.dashboard.qualificationPeriodBanner.hyperlinkText");

	// const canShowBanner = useShowQualificationBanner();
	// if (!canShowBanner) return <></>;
	return (
		<Fragment>
			{(windowWidth as number) >= 1024 ? (
				<TopBanner className={"dash-qualification-banner"} status={undefined} nonDismissable persistent>
					<div className={"dash-temp-banner"}>
						<img style={{ paddingBottom: "30px", height: "60px" }} src={"/assets/icons/meDDicon/vehicle-info-icon-48.svg"} />
						<div className={"fds-p--x-1"}>
							<span className={"fds-color--secondary fds-weight--bold"}>{t("pages.dashboard.qualificationPeriodBanner.important")}: </span>
							{/* {t("pages.dashboard.qualificationPeriodBanner.voluntaryElectricVehicleProgram")} <span className={"fds-weight--bold"}>{t("pages.dashboard.qualificationPeriodBanner.qualificationLaunchPeriod")}</span>
							<span className="">{t("pages.dashboard.qualificationPeriodBanner.qualificationProgramPeriod")}</span>
							<span className={"fds-weight--bold"}>{t("pages.dashboard.qualificationPeriodBanner.qualificationPeriod")}</span>
							<span className={"fds-weight--bold fds-color__text--success1"}>{t("pages.dashboard.qualificationPeriodBanner.qualificationActiveDealers")}</span> {t("pages.dashboard.qualificationPeriodBanner.duringThisPeriodCompleteQualifiers")}
							{t("pages.dashboard.qualificationPeriodBanner.immediateAction") && <span className="fds-weight--bold fds-color__text--error1">{t("pages.dashboard.qualificationPeriodBanner.immediateAction")}</span>}
							{t("pages.dashboard.qualificationPeriodBanner.contactInfo")}
							{linkText && (
								<a href={edcLink} target="_blank" style={{ color: "#1700f4" }}>
									{linkText}
								</a>
							)}
							<span>{t("pages.dashboard.qualificationPeriodBanner.details")}</span> */}
							The Electric Vehicle Dashboard is currently undergoing testing.
							{false && <QualificationPeriodReminderBanner />}
						</div>
					</div>
				</TopBanner>
			) : (
				<TopBanner className={"dash-qualification-banner"} status={undefined} nonDismissable persistent>
					<div className={"dash-temp-banner"}>
						<div className={"fds-p--x-1"}>
							<span className={"fds-color--secondary fds-weight--bold"}>{t("pages.dashboard.qualificationPeriodBanner.important")}: </span>
							{/* {t("pages.dashboard.qualificationPeriodBanner.voluntaryElectricVehicleProgram")} <span className={"fds-weight--bold"}>{t("pages.dashboard.qualificationPeriodBanner.qualificationLaunchPeriod")}</span>
							<span className="">{t("pages.dashboard.qualificationPeriodBanner.qualificationProgramPeriod")}</span>
							<span className={"fds-weight--bold"}>{t("pages.dashboard.qualificationPeriodBanner.qualificationPeriod")}</span>
							<span className={"fds-weight--bold fds-color__text--success1"}>{t("pages.dashboard.qualificationPeriodBanner.qualificationActiveDealers")}</span> {t("pages.dashboard.qualificationPeriodBanner.duringThisPeriodCompleteQualifiers")}
							{t("pages.dashboard.qualificationPeriodBanner.immediateAction") && <span className="fds-weight--bold fds-color__text--error1">{t("pages.dashboard.qualificationPeriodBanner.immediateAction")}</span>}
							{t("pages.dashboard.qualificationPeriodBanner.contactInfo")}
							{linkText && (
								<a href={edcLink} target="_blank" style={{ color: "#1700f4" }}>
									{linkText}
								</a>
							)}
							<span>{t("pages.dashboard.qualificationPeriodBanner.details")}</span>
							{false && <QualificationPeriodReminderBanner />} */}
							The Electric Vehicle Dashboard is currently undergoing testing. Please revisit the site on October 15 to experience the newly revamped Dashboard.
						</div>
					</div>
				</TopBanner>
			)}
		</Fragment>
	);
};

export default QualificationPeriodBanner;
